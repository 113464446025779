<template>
  <div>
    <v-card id="voucher-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <div class="d-flex align-center mr-3">
            <!-- filter status -->
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Status')"
              :class="{ 'voucher-list-status': $vuetify.breakpoint.smAndUp }"
            ></v-select>
          </div>

          <div class="d-flex align-center mr-3">
            <!-- filter amount -->
            <v-select
              v-model="amountFilter"
              :items="amountOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Amount')"
              :class="{ 'voucher-list-amount': $vuetify.breakpoint.smAndUp }"
            ></v-select>
          </div>
        </div>
        <div class="d-flex align-center pb-5">
          <div class="d-flex align-center">
            <!-- filter issue type -->
            <v-select
              v-model="issueTypeFilter"
              :items="issueTypeOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Issue Type')"
              :class="{ 'voucher-list-issue-type': $vuetify.breakpoint.smAndUp }"
            ></v-select>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center pb-5">
          <!-- search voucher -->
          <v-tooltip
            :left="$vuetify.breakpoint.smAndUp"
            :top="$vuetify.breakpoint.xsOnly"
            :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
            :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
            :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                class="mr-3 hover-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <h3 class="my-2">
              {{ t('How to use the search bar') }}
            </h3>
            <p class="mb-1">
              {{ t('You can search using') }}:
            </p>
            <ul class="mb-2">
              <li>
                {{ t('Code') }}
              </li>
            </ul>
          </v-tooltip>
          <v-text-field
            v-model="searchText"
            :append-icon="icons.mdiMagnify"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('Search Voucher')"
            :class="{ 'voucher-list-search': $vuetify.breakpoint.mdAndUp }"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="voucher-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="vouchers"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        hide-default-footer
        :disable-sort="true"
        mobile-breakpoint="0"
        class="voucher-table text-no-wrap d-none d-sm-block"
      >
        <!-- Issued By -->
        <template #[`item.issued_by`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.issued_by.id } }"
          >
            {{ item.issued_by.receipt_number }}
          </router-link>
        </template>
        <!-- Receipt Link -->
        <template #[`item.receipt_url`]="{item}">
          <a
            v-if="item.receipt_url !== 'NULL'"
            :href="item.receipt_url"
            target="_blank"
            style="text-decoration: none;"
          >
            {{ item.receipt_url }}
          </a>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmColumns"
        :items="vouchers"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        hide-default-footer
        :disable-sort="true"
        mobile-breakpoint="0"
        class="voucher-table text-no-wrap d-block d-sm-none"
      >
        <!-- Issued By -->
        <template #[`item.issued_by`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.issued_by.id } }"
          >
            {{ item.issued_by.receipt_number }}
          </router-link>
        </template>
        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <td v-if="item.used_at !== 'NULL'">
            <v-btn
              icon
              class="v-data-table__expand-icon"
              :class="{'v-data-table__expand-icon--active' : isExpanded}"
              @click="expand(!isExpanded)"
            >
              <v-icon>{{ icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </td>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-4"
          >
            <small>{{ t('Used At') }}: {{ item.used_at }}</small><br>
            <small>{{ t('Used By') }}: {{ item.used_by }}</small><br>
            <small>{{ t('Receipt Link') }}:</small><br>
            <div style="max-width: 300px">
              <a
                v-if="item.receipt_url !== 'NULL'"
                :href="item.receipt_url"
                target="_blank"
                style="text-decoration: none;"
                class="text-wrap"
              >
                <small>{{ item.receipt_url }}</small>
              </a>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAlertCircleOutline,
  mdiMagnify,
  mdiChevronDown,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
  watch,
  computed,
} from '@vue/composition-api'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Code'), width: '200', value: 'code' },
        { text: t('Amount'), width: '200', value: 'amount' },
        { text: t('Used At'), width: '200', value: 'used_at' },
        { text: t('Used By'), width: '200', value: 'used_by' },
        { text: t('Issued By'), width: '200', value: 'issued_by' },
        { text: t('Receipt Link'), width: '360', value: 'receipt_url' },
      ]
    ))
    const tableSmColumns = computed(() => (
      [
        { text: t('Code'), width: '200', value: 'code' },
        { text: t('Amount'), width: '200', value: 'amount' },
        { text: t('Issued By'), width: '200', value: 'issued_by' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Properties
    const vouchers = ref([])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const listLengthOptions = ref([20, 50, 100])
    const options = ref({
      sortBy: ['code'],
      sortDesc: [false],
    })
    const expanded = ref([])
    const loading = ref(false)
    const searchText = ref('')
    const statusFilter = ref(null)
    const statusOptions = ref(['Available', 'Used'])
    const amountFilter = ref(null)
    const amountOptions = ref(['$5.00', '$10.00', '$20.00'])
    const issueTypeFilter = ref(null)
    const issueTypeOptions = ref(['Digitally generated', 'Printed'])

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchVouchers = () => {
      store
        .dispatch('voucherStore/fetchVouchers', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          search_text: searchText.value,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          status: statusFilter.value,
          filter_amount: amountFilter.value,
          issue_type: issueTypeFilter.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          vouchers.value = data
          vouchers.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          tablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching vouchers. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchVouchers()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchVouchers()
    }

    // Watch
    let timer = null
    watch([searchText, statusFilter, amountFilter, issueTypeFilter], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        isLoading()
        fetchVouchers()
      }, 200)
    })

    // Mounted
    onMounted(() => fetchVouchers())

    return {
      t,
      vouchers,
      options,
      loading,
      tableColumns,
      tableSmColumns,
      tablePagination,
      listLengthOptions,
      expanded,
      searchText,
      statusOptions,
      statusFilter,
      amountFilter,
      amountOptions,
      issueTypeFilter,
      issueTypeOptions,
      listLengthChange,
      pageChange,
      fetchVouchers,

      icons: {
        mdiAlertCircleOutline,
        mdiMagnify,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #voucher-list {
    .voucher-list-search {
      width: 25.2rem;
    }

    .voucher-list-status,
    .voucher-list-amount,
    .voucher-list-issue-type {
      max-width: 12.2rem;
    }

    .voucher-list-row-selection {
      max-width: 5.3rem;
    }
  }
</style>

<style>
@media (max-width: 599px) {
  .voucher-table th {
    min-width: 0 !important;
  }
}
</style>
